import { applyMiddleware, createStore, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
// import data from "./data";
import {
  productListReducer,
  productDetailsReducer,
} from "./reducers/productReducers";

const initialState = {};
// const reducer = (state, action) => {
//   return {
//     products: data.products,
//   };
// };

const reducer = combineReducers({
  productList: productListReducer,
  productDetails: productDetailsReducer,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;
