import React from "react";

const Product = (props) => {
  const { product } = props;
  return (
    <div key={product._id} className="cardproduit">
      {/* <a href={`/product/${product._id}`}>
        <img className="medium" src={product.image} alt={product.name} />
      </a>
      <div className="card-body">
        <a href={`/product/${product._id}`}>
          <h2>{product.name}</h2>
        </a>
        <a>{product.description}</a>
        <br />
        <a>Sur devis</a>
      </div> */}

      <a
        href={`/product/${product._id}`}
        className="cardproduit"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img
          src={product.image}
          className="cardproduit_image"
          alt={product.name}
        />
      </a>
      <div className="cardproduit_overlay">
        <div className="cardproduit_header">
          <svg className="cardproduit_arc" xmlns="http://www.w3.org/2000/svg">
            <path d="M 40 80 c 22 0 40 -22 40 -40 v 40 Z" />
          </svg>
          <img
            className="cardproduit_thumb"
            src={product.image}
            alt={product.name}
          />
          <div className="cardproduit_header-text">
            <a
              href={`/product/${product._id}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <h3 className="cardproduit_title">{product.name}</h3>
            </a>
            <span className="cardproduit_status">sur devis</span>
          </div>
        </div>
        <p className="cardproduit_description">{product.category}</p>
      </div>
    </div>
  );
};

export default Product;
