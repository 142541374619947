import React from "react";
import { Link } from "react-router-dom";


const Navbar = () => {
  return (

    <nav className="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
      <div className="container px-4 px-lg-5">
        {/* <a className="navbar-brand" href="/">
          Point Fermeture
        </a> */}
        <a className="navbar-brand" href="/">
          <img src={process.env.PUBLIC_URL + "/images/logo.png"} className="img_logo" alt="logo" />
        </a>

        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ms-auto">


            {/* --------------- LA GAMME ACIER --------------- */}

            <li className="dropdown">
              <a className="nav-link">
                <span>ACIER </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  className="bi bi-chevron-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </a>
              <ul>
                {/* <li><a className="nav-link" href="#">Gamme de Profilé 50</a></li> */}
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Gamme de Profilé 50' }}>Gamme de Profilé 50</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Gamme de profilé 40' }}>Gamme de profilé 40</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Gamme de profilé à rupture de pont thermique 60' }}>Gamme de profilé à rupture de pont thermique 60</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Facades' }}>Facades</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Portes accordéons' }}>Portes accordéons</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Garde du corps' }}>Garde du corps</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Verrières' }}>Verrières</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Portails & portillons' }}>Portails & portillons</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Porte dentrée' }}>Porte d'entrée</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Etagères' }}>Etagères</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Terrasses' }}>Terrasses</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Vérandas' }}>Vérandas</Link></li>
              </ul>
            </li>

            {/* --------------- LA GAMME ALUMINIUM --------------- */}

            <li className="dropdown">
              <a className="nav-link">
                <span>ALUMINIUM </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  className="bi bi-chevron-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </a>
              <ul>

                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Gamme de profilé Série froide' }}>Gamme de profilé Série froide</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Gamme de profilé Rupture de pont thermique' }}>Gamme de profilé Rupture de pont thermique</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Gamme de Profilé ALUK' }}>Gamme de Profilé ALUK</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Fenêtres & porte fenêtre' }}>Fenêtres & porte fenêtre</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Façades' }}>Façades</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Coulissant' }}>Coulissant</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Fenêtres & porte fenêtre' }}>Fenêtres & porte fenêtre</Link></li>
              </ul>
            </li>

            {/* --------------- LA GAMME PVC --------------- */}

            <li className="dropdown">
              <a className="nav-link">
                <span>PVC </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  className="bi bi-chevron-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </a>
              <ul>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Le profil Réhau' }}>Le profil Réhau</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Le profil Gealan' }}>Le profil Gealan</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Portes Garages en aluminium' }}>Portes Garages en aluminium</Link></li>
              </ul>
            </li>

            {/* --------------- VÉRANDAS --------------- */}

            <li className="dropdown">
              <a className="nav-link">
                <span>VÉRANDAS </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  className="bi bi-chevron-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </a>
              <ul>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Vérandas' }}>Vérandas</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Terrasses' }}>Terrasses</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Toitures' }}>Toitures</Link></li>
              </ul>
            </li>

            {/* --------------- PORTAIL --------------- */}

            <li className="dropdown">
              <a className="nav-link">
                <span>PORTAIL </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  className="bi bi-chevron-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </a>
              <ul>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Portails' }}>Portails</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Portillons' }}>Portillons</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Portes dentrée' }}>Portes d'entrée</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Portes garages sectionnelles' }}>Portes garages sectionnelles</Link></li>
              </ul>
            </li>

            {/* --------------- MÉTALLURGIE --------------- */}

            <li className="dropdown">
              <a className="nav-link">
                <span>MÉTALLURGIE </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  className="bi bi-chevron-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </a>
              <ul>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Découpe laser' }}>Découpe laser</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Tôlerie' }}>Tôlerie</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Pliage et cisaille' }}>Pliage et cisaille</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Poutrelles' }}>Poutrelles</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Tubes' }}>Tubes</Link></li>

              </ul>
            </li>



            {/* --------------- PROTECTION SOLAIRE --------------- */}

            <li className="dropdown">
              <a className="nav-link">
                <span>PROTECTION SOLAIRE  </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  className="bi bi-chevron-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </a>
              <ul>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Stores' }}>Stores</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Volets roulants' }}>Volets roulants</Link></li>
                <li><Link className="nav-link" to={{ pathname: '/category', search: '?category=Rideaux métalliques' }}>Rideaux métalliques</Link></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
