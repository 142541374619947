import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { detailsProduct } from "../actions/productActions";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/Messagebox";
import data from "../data";

const ProductScreen = (props) => {
  const product = data.products.find((x) => x._id === props.match.params.id);
  // const dispatch = useDispatch()
  // const productId = props.match.params.id
  // const productDetails = useSelector((state) => state.productDetails);
  // const { loading, error, product } = productDetails;

  // useEffect(() => {
  //   dispatch(detailsProduct(productId))
  // }, [dispatch,productId]);

  if (!product) {
    return <div>Product Not Found</div>;
  }
  // const onClose = () => {
  //   window.opener = null;
  //   window.open('', '_self');
  //   window.close();
  // }
  return (
    <div className="row center">
      <div>
        <div className="row top">
          {/* <div className="col">
            <Link className="link-back" to="/productlist">
              <button className="btn btn-primary">Back&nbsp;</button>
            </Link>
            <Link className="link-close">
            <button className="btn btn-primary" onClick={onClose}>Close</button>
            </Link>
          </div> */}

          <div className="detail_img">
            <img className="medium" src={product.image} alt={product.name}></img>
          </div>
          <div className="detail_text">
            <ul>
              <li>
                <h2>{product.name}</h2>
              </li>
              <li>{product.description && (
                <>
                  <a>DESCRIPTION </a>
                  <p>{product.description}</p>
                </>
              )}
              </li>
              <li>{product.reference && (
                <>
                  <a>RÉFÉRENCE </a>
                  <p>{product.reference}</p>
                </>
              )}
              </li>
              <li>{product.caracteristiques && (
                <>
                  <a>CARACTÉRISTIQUES </a>
                  <p>{product.caracteristiques}</p>
                </>
              )}
              </li>
              <li>{product.options && (
                <>
                  <a>OPTIONS </a>
                  <p>{product.options}</p>
                </>
              )}
              </li>
            </ul>
          </div>
        </div>
        <div className="interesse">
          <div><a>VOUS POURRIEZ AUSSI ÊTRE INTÉRESSÉ PAR :</a></div>
          <hr />
        </div>
      </div>
    </div>
  );
};

export default ProductScreen;
