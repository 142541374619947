import React from "react";
import { Route } from "react-router-dom";
import SearchBox from "../components/SearchBox";

const HomeScreen = () => {
  return (
    <div>
      <header className="masthead">
        <div className="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
          <div className="d-flex justify-content-center">
            <div className="text-center">
              <h1 className="mx-auto my-0 text-uppercase">Point Fermeture</h1>
              <h2 className="text-white mx-auto mt-4 mb-5">
                Fabricant expert du sur mesure répondant à vos besoins
              </h2>
              <a className="btn btn-primary" href="/productlist">
                Nos Produits
              </a>

              {/* ------------------ Search part ------------------ */}
              <div className="home_search">
                <div className="search_part">
                  <Route
                    render={({ history }) => (
                      <SearchBox history={history}></SearchBox>
                    )}
                  ></Route>
                </div>
                <button
                  className="navbar-toggler navbar-toggler-right"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarResponsive"
                  aria-controls="navbarResponsive"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="about-section text-center" id="about">
        <div className="container px-4 px-lg-4">
          <div className="row gx-4 gx-lg-5 justify-content-center">
            <div className="col-lg-6">
              <h2 className="text-white mb-4">Nos buts et objectifs</h2>
              <p className="text-white">
                Mettre en avant notre savoir faire du sur mesure pour répondre à
                tous vos besoins. Proposer une gamme de produits innovants et
                durable.
              </p>
            </div>
          </div>
          <div className="cover-img">
            <img
              className="img-fluid-2"
              src={process.env.PUBLIC_URL + "/images/productPicture-NYON-1.jpg"}
              alt="..."
            />
          </div>
        </div>
      </section>

      <section className="projects-section bg-light" id="projects">
        <div className="container px-4 px-lg-5">
          <div className="row gx-0 mb-4 mb-lg-5 align-items-center">
            <div className="col-xl-8 col-lg-7">
              <img
                className="img-fluid-1 mb-3 mb-lg-0"
                src={
                  process.env.PUBLIC_URL + "/images/productPicture-SELECT-3.jpg"
                }
                alt="..."
              />
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="featured-text text-center text-lg-left">
                <h4>Point Fermeture</h4>
                <p className="text-black mb-0">
                  Point Fermeture est une entreprise spécialisée dans les
                  menuiseries en acier, aluminium, PVC et stores. Elle réalise
                  des vérandas, façades, fenêtres, verrières, cloisons,
                  portails, portillons, porte d'entrée, garde du corps,
                  étagères, rideaux métalliques, volet roulants et stores.
                </p>
              </div>
            </div>
          </div>



          <div className="row gx-0 mb-5 mb-lg-0 justify-content-center">
            <div className="col-lg-6">
              <img
                className="img-fluid"
                src={process.env.PUBLIC_URL + "/images/img_4_1.jpg"}
                alt="..."
              />
            </div>
            <div className="col-lg-6">
              <div className="bg-black text-center h-100 project">
                <div className="d-flex h-100">
                  <div className="project-text w-100 my-auto text-center text-lg-left">
                    <h4 className="text-white">ACIER</h4>
                    <p className="mb-0 text-white">
                      Nos systèmes de profilés se distinguent par un haut niveau
                      de qualité, de fiabilité, de sécurité et une exploitation
                      rentable, même dans des conditions extrêmes. Notre
                      systèmes de profilés de porte prouvent leur supériorité
                      tant dans les constructions neuves que dans
                      l'assainissement de batiments plus anciens.
                    </p>
                    <hr className="d-none d-lg-block mb-0 ms-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row gx-0 mb-5 mb-lg-0 justify-content-center">
            <div className="col-lg-6">
              <img
                className="img-fluid"
                src={process.env.PUBLIC_URL + "/images/img_4_2.jpg"}
                alt="..."
              />
            </div>
            <div className="col-lg-6 order-lg-first">
              <div className="bg-black text-center h-100 project">
                <div className="d-flex h-100">
                  <div className="project-text w-100 my-auto text-center text-lg-right">
                    <h4 className="text-white">ALUMINIUM</h4>
                    <p className="mb-0 text-white">
                      Les menuiseries alu présentent de nombreux avantages
                      notamment pour une construction de façade et cloisons.
                      Comme le PVC, différents types de menuiseries comme des
                      fenêtres, portes, baies vitrées, pergolas, portails,
                      vérandas, volets, portes-fenêtres peuvent être fabriqués
                      avec de l’aluminium.
                    </p>
                    <hr className="d-none d-lg-block mb-0 me-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />
          <br />
          <br />
          <br />



          <div className="row gx-0 mb-5 mb-lg-0 justify-content-center">
            <div className="col-lg-6">
              <img
                className="img-fluid"
                src={process.env.PUBLIC_URL + "/images/img_4_3.jpg"}
                alt="..."
              />
            </div>
            <div className="col-lg-6">
              <div className="bg-black text-center h-100 project">
                <div className="d-flex h-100">
                  <div className="project-text w-100 my-auto text-center text-lg-left">
                    <h4 className="text-white">PVC</h4>
                    <p className="mb-0 text-white">
                      La fenêtre PVC est le type de fenêtre le plus installé en
                      rénovation en France et ce n'est pas sans raison : En
                      effet c'est une fenêtre très polyvalente grâce à son
                      exceptionnelle stabilité thermique, sa résistance aux
                      chocs, sa tenue dans le temps et sa facilité d’entretien.
                      Elle saura faire face aux intempéries.
                    </p>
                    <hr className="d-none d-lg-block mb-0 ms-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row gx-0 mb-5 mb-lg-0 justify-content-center">
            <div className="col-lg-6">
              <img
                className="img-fluid"
                src={process.env.PUBLIC_URL + "/images/img_4_4.jpg"}
                alt="..."
              />
            </div>
            <div className="col-lg-6 order-lg-first">
              <div className="bg-black text-center h-100 project">
                <div className="d-flex h-100">
                  <div className="project-text w-100 my-auto text-center text-lg-right">
                    <h4 className="text-white">STORES</h4>
                    <p className="mb-0 text-white">
                      Nos solutions de protection solaire contre le soleil et
                      les intempéries pour la maison et le jardin constituent
                      une protection sûre. Selon le type de store, elles
                      protègent également de la pluie, du vent et des regards
                      indiscrets. Une grande palette de toile qui est à votre
                      disposition pour choisir la couleur que vous souhaitez et
                      même pour l'armature.
                    </p>
                    <hr className="d-none d-lg-block mb-0 me-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />
          <br />
          <br />
          <br />



          <div className="row gx-0 mb-5 mb-lg-0 justify-content-center">
            <div className="col-lg-6">
              <img
                className="img-fluid"
                src={process.env.PUBLIC_URL + "/images/img_4_3.jpg"}
                alt="..."
              />
            </div>
            <div className="col-lg-6">
              <div className="bg-black text-center h-100 project">
                <div className="d-flex h-100">
                  <div className="project-text w-100 my-auto text-center text-lg-left">
                    <h4 className="text-white">Vérandas</h4>
                    <p className="mb-0 text-white">
                      Que ce soit dans votre habitat ou bien dans votre magasin,
                      une Véranda est l'élément indispensable pour agrandir
                      votre espace de vie et de travail.Notre Véranda présente
                      les performances d'isolation et étanchéité requises pour
                      en faire un réel espace de vie idéal pour les projets
                      d'agrandissement.{" "}
                    </p>
                    <hr className="d-none d-lg-block mb-0 ms-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row gx-0 mb-5 mb-lg-0 justify-content-center">
            <div className="col-lg-6">
              <img
                className="img-fluid"
                src={process.env.PUBLIC_URL + "/images/img_4_4.jpg"}
                alt="..."
              />
            </div>
            <div className="col-lg-6 order-lg-first">
              <div className="bg-black text-center h-100 project">
                <div className="d-flex h-100">
                  <div className="project-text w-100 my-auto text-center text-lg-right">
                    <h4 className="text-white">Distributeur Métallurgie</h4>
                    <p className="mb-0 text-white">
                      Point fermeture distributeur de toles, de tubes, et de
                      poutrelles, nous vous accompagnons dans vos projets.
                      Découpe au laser sur nos tôles avec les motifs que vous
                      choissisez.
                    </p>
                    <hr className="d-none d-lg-block mb-0 me-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-section bg-black">
        <div className="container px-4 px-lg-5">
          <div className="row gx-4 gx-lg-5">
            <div className="col-md-4 mb-3 mb-md-0">
              <div className="card py-4 h-100">
                <div className="card-body text-center">
                  <i className="fas fa-map-marked-alt text-primary mb-2"></i>
                  <h4 className="text-uppercase m-0">Address</h4>
                  <hr className="my-4 mx-auto" />
                  <div className="small text-black-50">
                    48 rue des roches 93100 Montreuil
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-3 mb-md-0">
              <div className="card py-4 h-100">
                <div className="card-body text-center">
                  <i className="fas fa-envelope text-primary mb-2"></i>
                  <h4 className="text-uppercase m-0">Email</h4>
                  <hr className="my-4 mx-auto" />
                  <div className="small text-black-50">
                    <a href="#!">point.fermeture@gmail.com</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-3 mb-md-0">
              <div className="card py-4 h-100">
                <div className="card-body text-center">
                  <i className="fas fa-mobile-alt text-primary mb-2"></i>
                  <h4 className="text-uppercase m-0">Phone</h4>
                  <hr className="my-4 mx-auto" />
                  <div className="small text-black-50">09 80 88 86 96</div>
                </div>
              </div>
            </div>
          </div>
          <div className="social d-flex justify-content-center">
            <a className="mx-2" href="#!">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="mx-2" href="#!">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="mx-2" href="#!">
              <i className="fab fa-github"></i>
            </a>
          </div>
        </div>
      </section>

      <footer className="footer bg-black small text-center text-white">
        <div class="row">
          <div class="column">
            <h5>À PROPOS DE NOUS</h5>
            <a>Mentions légales</a>
          </div>
          <div class="column">
            <h5>AIDE</h5>
            <a>Espace Sécurité</a>
          </div>
          <div class="column">
            <h5>USINE DE MONTREUIL</h5>
            <a></a>
            <a></a>
            <a></a>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="container px-4 px-lg-5">
          Copyright &copy; Point Fermeture 2023
        </div>
      </footer>
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>
      <script src="js/scripts.js"></script>
    </div>
  );
};

export default HomeScreen;
