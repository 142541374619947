const data = {
  products: [
    {
      _id: "1",
      name: "PORTES ALUK UNIVERS 54",
      image: process.env.PUBLIC_URL + "/images/image_produits/portes_aluk_univers54.jpg",
      category: "Gamme de Profilé ALUK",
      reference: "ALUK - UNIVERS 54",
      description: "Le système de porte est l'indispensable des projets résidentiels. Sa très grande polyvalence et la qualité de ses finitions permettent de l'intégrer à la plupart des configurations.",
      caracteristiques: "• Porte simple action\n• Rupture de pont thermique\n• Ouvertures à la française ou à l'anglaise\n• Remplissages possibles jusqu'à 36 mm, (différents panneaux de remplissage)\n• Solution avec seuil plat PMR et seuil drainable et proposant un large choix de fermetures\n• Cette solution de porte peut intégrer différents accessoires, lui permettant ainsi d'être mise en œuvre dans des configurations résidentielles ou non résidentielles, en simple action\n• Grâce à des lignes galbées, cette porte s'intégre aussi bien dans les environnements traditionnels que modernes",
      options: "• Intégration d'un panneau de porte\n• Accessoires à la teinte\n• Ouverture à la française, à l'anglaise, 1 et 2 vantaux",
    },
    {
      _id: "2",
      name: "COULISSANTS ALUK REGATE 28",
      image: process.env.PUBLIC_URL + "/images/image_produits/COULISSANTS_ALUK_REGATE_28.jpg",
      category: "Gamme de Profilé ALUK",
      reference: "ALUK - REGATE 28",
      description: "Le coulissant aux formes traditionnelles et arrondies est l'incontournable chantier. Les différentes options de fermetures et le large choix de profilés et d'accessoires confèrent à ce système très complet, sécurité et grande polyvalence pour des projets résidentiels comme collectifs.",
      caracteristiques: "• Offre complète et performante.\n• Chariots autorisant des ouvrants jusqu'à 200 Kg/vtl.\n• Plusieurs solutions monobloc ou à pied de clippage.\n• Profils spécifiques pour la pose en rénovation.\n• Simplicité et rapidité de mise en œuvre.\n• Cette gamme de coulissant offre un large choix d'options de fermetures, pour garantir un confort d'utilisation et une sécurité optimale. Disponible en coulissant ou galandage, le Regate 28 s'intégre parfaitement à tous types d'intérieurs, résidentiel et non résidentiel.\n• Forme traditionnelle.",
      options: "• Plusieurs points de fermetures.\n• Rail / chariot INOX ou POM.\n• Large choix d'accessoires.\n• Large choix de teintes (accessoires à la teinte)\n• Châssis jusqu'à 3 rails et 6 vantaux, ensembles composés.\n• Coulissant jusqu’à 3 rails, 6 vantaux.\n• Galandage jusqu’à 2 rails, 4 vantaux.",
    },
    {
      _id: "3",
      name: "FENETRES REHAU TITANIUM DESIGN",
      image: process.env.PUBLIC_URL + "/images/image_produits/FENETRES_REHAU_TITANIUM_DESIGN.png",
      category: "Le profil Réhau",
      reference: "REHAU - TITANIUM DESIGN",
      description: "Les fenêtres Titanium-Design en PVC conviennent particulièrement aux constructions neuves/chantiers.\nCe type de profilés permet de fabriquer des fenêtres et portes-fenêtres au design traditionnel, tout en laissant la possibilité d’y associer formes et couleurs différentes, en fonction de chaque exigence.",
      caracteristiques: "• Clair de vitrage optimisé grâce à un battement central de 116 mm.\n• Parclose et couvre-joint assortis pour une esthétique intérieure et extérieure harmonieuse.",
      options: "",
    },
    // {
    //   _id: "4",
    //   name: "FENETRE DE TOIT",
    //   image: process.env.PUBLIC_URL + "/images/image_produits/FENETRE_DE_TOIT.jpeg",
    //   category: "Fenetre de Toit",
    //   reference: "ROTO Designo R7 fenêtre de toit PVC à ouverture par projection 38°",
    //   description: "Designo R7\nOuverture par projection 38°\nLa technologie de projection à 38° offre un net avantage en matière de liberté de mouvement et de confort par rapport aux fenêtres à rotation clossiques.\nRoto Designo R7 saura aussi vous convaincre par son design.",
    //   caracteristiques: "",
    //   options: "",
    // },
    {
      _id: "5",
      name: "PORTES ALUK UNIVERS 54",
      image: process.env.PUBLIC_URL + "/images/image_produits/PORTES_ALUK_UNIVERS_54.jpeg",
      category: "Gamme de Profilé ALUK",
      reference: "ALUK - UNIVERS 54",
      description: "Le système de porte est l'indispensable des projets résidentiels. Sa très grande polyvalence et la qualité de ses finitions permettent de l'intégrer à la plupart des configurations.",
      caracteristiques: "• Porte simple action\n• Rupture de pont thermique\n• Ouvertures à la française ou à l'anglaise\n• Remplissages possibles jusqu'à 36 mm, (différents panneaux de remplissage)\n• Solution avec seuil plat PMR et seuil drainable et proposant un large choix de fermetures\n• Cette solution de porte peut intégrer différents accessoires, lui permettant ainsi d'être mise en œuvre dans des configurations résidentielles ou non résidentielles, en simple action\n• Grâce à des lignes galbées, cette porte s'intégre aussi bien dans les environnements traditionnels que modernes",
      options: "• Intégration d'un panneau de porte\n• Accessoires à la teinte\n• Ouverture à la française, à l'anglaise, 1 et 2 vantaux",
    },
    {
      _id: "6",
      name: "VERANDAS ALUK 67V EPINE",
      image: process.env.PUBLIC_URL + "/images/image_produits/VERANDAS_ALUK_67V_EPINE.jpeg",
      category: "Gamme de Profilé ALUK",
      reference: "ALUK - 67V EPINE",
      description: "Cette solution permet de réaliser une véranda sur-mesure : configurations, couleurs, options... Son esthétique traditionnelle est adaptée aux toitures vitrées laissant ainsi pénétrer la lumière du jour de façon optimale, tout en apportant un bien-être incomparable toute l'année.",
      caracteristiques: "• Pour les grandes portées, les chevrons épines peuvent être renforcés, permettant de doubler l'inertie initiale.\n• Panneau de remplissage ou surface vitrée jusqu'à 72 mm avec une inclinaison maximum de 40°.\n• Esthétique traditionnelle.\n• Compatible avec les gammes 54 et 67 AluK.",
      options: "• Spot de lumière intérieur\n• Coffre de brise soleil orientable (BSO)/volet roulant intégré\n• Accessoires décoratifs\n• Aérateur de toiture; aérateur de coulissant.",
    },
    {
      _id: "7",
      name: "Terrasse fermée ou ouverte",
      image: process.env.PUBLIC_URL + "/images/image_produits/Terrasse_fermee_ou_ouverte.jpg",
      category: "Terrasses",
      reference: "",
      description: "La création d’une terrasse fermée ou ouverte sur le trottoir représente un potentiel d’activité supplémentaire pour les brasseries, cafés ou restaurants qui cherchent à augmenter le nombre de tables et de couverts pour leur service, à créer un espace fumeur ou simplement permettre aux clients de profiter du soleil.\n\nCette extension correspond cependant à un empiètement d’une partie du domaine public et relève donc de la réglementation en vigueur de chaque ville. Des démarches administratives sont donc à prévoir avant d’entreprendre votre projet d’extension.",
      caracteristiques: "",
      options: "",
    },
    {
      _id: "8",
      name: "Devanture de magasin",
      image: process.env.PUBLIC_URL + "/images/image_produits/Devanture_de_magasin.jpg",
      category: "Façades",
      reference: "",
      description: "Les différentes profilés Aluminium permettent la construction de diverse châssis aux conceptions architecturales variées. La grande variété de combinaisons offerte par ces systèmes laisse libre cours à la créativité des architectes pour la conception d’ouvrages les plus diversifiés. Sur la base de systèmes homologués, il est possible de concrétiser quasiment tous vos souhaits.",
      caracteristiques: "• Châssis fixe vitré\n• Porte un vantail ou deux vantaux sur paumelles avec serrure multipoints\n• Porte sur pivots encastré avec penture haute et basse\n• Porte repliable avec ou sans rail en applique\n• Porte avec SAS d’entrée\n• Porte sortie de secours avec serrure antipanique et ou crémone pompier\n• Porte entrée d’immeuble avec gâche électrique ou bandeau ventouse",
      options: "",
    },
    {
      _id: "9",
      name: "VERRIERE INTERIEUR",
      image: process.env.PUBLIC_URL + "/images/image_produits/VERRIERE_INTERIEUR.png",
      category: "Verrières",
      reference: "",
      description: "Nos verrières intérieures en aluminium sont fabriquées sur mesure selon votre style et vos envies.\n\nLes séparations de pièces réalisées avec nos verrières apportent une ambiance chic et moderne aux intérieurs.\n\nElles permettent de délimiter les différents espaces tout en conservant la lumière naturelle.\n\nElle est parfaitement modulable grâce à l'ajout de traverses horizontales qui donne vie à votre pièce.",
      caracteristiques: "",
      options: "",
    },
    {
      _id: "10",
      name: "VOLET ROULANT ALU",
      image: process.env.PUBLIC_URL + "/images/image_produits/VOLET_ROULANT_ALU.jpeg",
      category: "Volets roulants",
      reference: "",
      description: "Consommation énergétique diminuée, plus de confort et de possibilité d’aménagement, coûts de montage réduits. Nos volets roulants conçus sur mesure sont les solutions idéales pour la rénovation ou la construction neuve.\n\nLe savoir faire et la qualité optimale des matériaux offrent des avantages durables.\n\nNos solutions minimisent les pertes de chaleur et réduisent les frais de chauffage. Elles garantissent une protection globale et améliorent l’atmosphère ambiante, même en été. Elles brillent par leur diversité en termes d’équipement et de design, que vous choisissiez des volets roulants en applique ou des systèmes monoblocs.\n\nLe volet roulant en applique universel adaptable pour un montage dans l’encadrement. Avec les trois variantes d’inclinaisons du coffre de volet roulant, il est extrêmement variable et offre toujours une apparence rectiligne et claire. Vous trouverez le modèle de vos rêves parmi les nombreuses possibilités d’équipement.",
      caracteristiques: "• coffre en aluminium fermé, profilé par roulage ou extrudé\n• inclinaison de 45°\n• surface nécessaire minime, peu d’entretien requis\n• Commande : Moteur radio, commande motorisée confortable ou à manivelle\n• Motorisation : SIMU",
      options: "",
    },
    {
      _id: "11",
      name: "Rideau métallique",
      image: process.env.PUBLIC_URL + "/images/image_produits/Rideau_métallique.jpg",
      category: "Rideaux métalliques",
      reference: "",
      description: "Le rideau métallique représente une solution simple, robustepour fermer l’accès de locaux et protéger une vitrine.\n\nLever de rideaux chez TransAlu…Le rideau métallique s’installe aussi bien dans les petits commerces que dans les grands hangars.\n\nLa conception originale et exclusive de la lame TransAlu, reconnue par les professionnels, offre une forte résistance à l’effraction et associe, en version micro perforée, esthétisme et sécurité.\n\n",
      caracteristiques: "• Lame pleine ou micro perforé en acier galvanisé\n• Manuel ou Motorisé\n• Bouton poussoir et boîte à clé (option avec débrayage)\n• Kit télécommande\n• Caisson d’habillage (coffre)\n• Possibilité de laquage époxy suivant nuancier RAL",
      options: "",
    },
    {
      _id: "12",
      name: "Tube rectangulaire galvanisé",
      image: process.env.PUBLIC_URL + "/images/image_produits/tubes/tube-rectangulaire-galvanise.jpg",
      category: "Tubes",
      reference: "TREG4020",
      description: "Tube rectangulaire galvanisé selon NF EN ISO 1461 \nAcier nuance S235/S275 \nGalvanisé à chaud selon NF EN ISO 10219 \nLongueur: 6.10m \nTaille: Largeur a x Hauteur b x Epaisseur e (mm)",
      caracteristiques: "• 40*20*2 Galvanisé\n• 40*20*2 Galvanisé\n• 40*30*2 Galvanisé\n• 50*30*2 Galvanisé\n• 60*30*2 Galvanisé\n• 60*40*2 Galvanisé\n• 80*40*2 Galvanisé",
      options: "",
    },
    {
      _id: "13",
      name: "Tube gaz sans soudure",
      image: process.env.PUBLIC_URL + "/images/image_produits/tubes/tube-gaz-sans-soudure.jpg",
      category: "Tubes",
      reference: "TSS172",
      description: "Tube gaz sans soudure\nFabrication sans soudure\nLongueur: 6.10m\nUtilisation spéciale pour les canalisations\nNous vendons aussi les coudes à souder compatibles\nTaille: Diamètre d x Epaisseur e (mm)",
      caracteristiques: "• 17.2 x 2.3\n• 21.3 x 2.6\n• 26.9 x 2.6\n• 33.7 x 3.6\n• 42.4 x 3.2",
      options: "",
    },
    {
      _id: "14",
      name: "Tube carré",
      image: process.env.PUBLIC_URL + "/images/image_produits/tubes/tube-carre-tca2506.jpg",
      category: "Tubes",
      reference: "TCA1215",
      description: "Tube carré acier \nAcier nuance S235/S275 \nLongueur: 6.10m \nTaille: Largeur a x Hauteur a x Epaisseur e (mm)",
      caracteristiques: "• 12*12*1.5\n• 14*14*1.5\n• 16*16*1.5\n• 20*20*1.5\n• 20*20*2\n• 25*25*2\n• 30*30*2",
      options: "",
    },
    {
      _id: "15",
      name: "Tube rectangle",
      image: process.env.PUBLIC_URL + "/images/image_produits/tubes/tube-rectangle-tre300200.jpg",
      category: "Tubes",
      reference: "TRE2010",
      description: "Tube rectangle acier  \nAcier nuance S235/S275 \nLongueur: 6.10m \nTaille: Largeur a x Hauteur b x Epaisseur e (mm)",
      caracteristiques: "• 20*10*1.5\n• 20*15*1.5\n• 25*15*1.5\n• 30*15*1.5\n• 30*15*2\n• 35*20*1.5\n• 40*10*1.5",
      options: "",
    },
    {
      _id: "16",
      name: "Tube rond",
      image: process.env.PUBLIC_URL + "/images/image_produits/tubes/tube-rond-tro1934.jpg",
      category: "Tubes",
      reference: "TRO1215",
      description: "Tube rond acier \nAcier nuance S235/S275 \nLongueur: 6.10m \n Taille: Diamètre d x Epaisseur e (mm)",
      caracteristiques: "• 12*1.5\n• 14*1.5\n• 16*1.5\n• 18*1.5\n• 20*2\n• 25*2",
      options: "",
    },
    {
      _id: "17",
      name: "Tube carré galvanisé",
      image: process.env.PUBLIC_URL + "/images/image_produits/tubes/tube-carre-galva-tcag503.jpg",
      category: "Tubes",
      reference: "TCAG202",
      description: "Tube carré galvanisé selon NF EN ISO 1461 \nAcier nuance S235/S275  \nGalvanisé à chaud selon NF EN ISO 10219 \nLongueur: 6.10m \nTaille: Largeur a x Hauteur a x Epaisseur e (mm) ",
      caracteristiques: "• 20*20*2\n• 25*25*2\n• 30*30*2\n• 40*40*2\n• 50*50*2",
      options: "",
    },
    {
      _id: "18",
      name: "Tube rond aluminium",
      image: process.env.PUBLIC_URL + "/images/image_produits/tubes/tube-carre-tca2506.jpg",
      category: "Tubes",
      reference: "TRA202",
      description: "Tube rond aluminium brut\nSelon norme NF EN 755\nNuance: AW6060T6\nLongueur: 6.05m\nTaille: Diamètre d x Epaisseur e (mm)",
      caracteristiques: "• 20 x 2mm\n• 25 x 2mm\n• 30 x 2mm\n• 40 x 2mm\n• 50 x 2mm",
      options: "",
    },
    {
      _id: "19",
      name: "Tube rectangle aluminium",
      image: process.env.PUBLIC_URL + "/images/image_produits/tubes/tube-rectangle-alu-traa50302.jpg",
      category: "Tubes",
      reference: "TRAA30202",
      description: "Tube rectangle aluminium brut\nSelon norme NF EN 755\nNuance: AW6060T6\nLongueur: 6.05m \nTaille: Largeur a x Hauteur b x Epaisseur e (mm)",
      caracteristiques: "• 30*20*2\n• 40*20*2\n• 50*30*2",
      options: "",
    },
    {
      _id: "20",
      name: "Tube carré aluminium",
      image: process.env.PUBLIC_URL + "/images/image_produits/tubes/tube-carre-alu-tcaa502.jpg",
      category: "Tubes",
      reference: "TCAA202",
      description: "Tube carré aluminium brut\nSelon norme NF EN 755\nNuance: AW6060T6\nLongueur: 6.05m\nTaille: Hauteur a x Largeur a x Epaisseur e (mm)",
      caracteristiques: "• 20*20*2\n• 25*25*2\n• 30*30*2\n• 40*40*2\n• 50*50*2",
      options: "",
    }, 
    {
      _id: "21",
      name: "Tube ouvert carré",
      image: process.env.PUBLIC_URL + "/images/image_produits/tubes/tube-carre-ouvert-touc351.jpg",
      category: "Tubes",
      reference: "TOUC20",
      description: "Tube ouvert carré acier \nProfilé à froid selon NF EN 10162\nAcier S235 JR selon NF EN 10025 \nLongueur: 6.10m \nTaille: Hauteur b x Largeur c x Epaisseur e (mm)",
      caracteristiques: "• 20*20*2\n• 25*25*2\n• 30*30*2\n• 35*35*2 O7\n• 35*35*2 O15",
      options: "",
    },
    {
      _id: "22",
      name: "Tube ouvert rectangulaire",
      image: process.env.PUBLIC_URL + "/images/image_produits/tubes/tube-rectangle-tour10050.jpg",
      category: "Tubes",
      reference: "TOUR3015",
      description: "Tube ouvert rectangulaire acier \nProfilé à froid selon NF EN 10162\nAcier S235 JR selon NF EN 10025\nLongueur: 6.10m\nTaille: Hauteur b x Largeur c x Epaisseur e (mm)",
      caracteristiques: "• 30x15x1.5\n• 40x20x2\n• 50x25x3\n• 80x40x3\n• 100x50x3",
      options: "",
    },
    {
      _id: "23",
      name: "UPN (Hauteur -200 mm)",
      image: process.env.PUBLIC_URL + "/images/image_produits/poutrelles/upn180.jpg",
      category: "Poutrelles",
      reference: "UPN80",
      description: "Poutrelle Acier UPN Selon NF EN 10279\nHauteur de moins de 200mm\nAcier S275 JR selon NF EN 10025\nLongueur maximale:\n  • 12,10m pour UPN80\n  • 15,10 pour toutes les autres sections\nPossibilité d'acheter à la coupe\nTaille: B x H x a (mm)",
      caracteristiques: "• UPN 80   (80*45*6)\n• UPN 100 (100*50*6)\n• UPN 120 (120*55*7)\n• UPN 140 (140*60*7)\n• UPN 160 (160*65*7.5)\n• UPN 180 (180*70*8)",
      options: "",
    },
    {
      _id: "24",
      name: "HEA (Hauteur -200 mm)",
      image: process.env.PUBLIC_URL + "/images/image_produits/poutrelles/hea180.jpg",
      category: "Poutrelles",
      reference: "HEA100",
      description: "Poutrelle Acier HEA Selon NF EN 10034\nHauteur de moins de 200mm\nAcier S275 JR selon NF EN 10025\nLongueur maximale : 15,10m \nPossibilité d'acheter à la coupe\nTaille: B x H x a (mm)",
      caracteristiques: "• HEA 100 (100*96*5)\n• HEA 120 (120*114*5)\n• HEA 140 (140*133*55)\n• HEA 160 (160*152*6)\n• HEA 180 (180*171*6)",
      options: "",
    },
    {
      _id: "25",
      name: "IPE (Hauteur -200 mm)",
      image: process.env.PUBLIC_URL + "/images/image_produits/poutrelles/ipe180.jpg",
      category: "Poutrelles",
      reference: "IPE80",
      description: "Poutrelle Acier IPE selon NF EN 10034\nHauteur de moins de 200mm\nAcier S275 JR selon NF EN 10025\nLongueur maximale: \n   • 12.10m pour IPE80\n   • 15.10m pour toutes les autres sections\nPossibilité d'acheter à la coupe\nTaille: B x H x a (mm)",
      caracteristiques: "• IPE 80 (80*46*3.8)\n• IPE 100 (100*55*4.1)\n• IPE 120 (120*64*4.4)\n• IPE 140 (140*73*4.7)\n• IPE 160 (160*82*5)",
      options: "",
    },
    {
      _id: "26",
      name: "Tôle aluminium brute",
      image: process.env.PUBLIC_URL + "/images/image_produits/tolerie/tole-aluminium-1050a-brut-abr10122.jpg",
      category: "Tôlerie",
      reference: "ABR10122",
      description: "Tôle plane aluminium brute \nComposition AW-AI99,5\nType 1050A H14/H24\nIl est possible en option de couper et/ou plier la tôle sur mesure. Veuillez nous adresser une demande par mail ou par le formulaire de contact du site.\nVente par feuille \nTaille: Largeur a x Hauteur b x Epaisseur e (mm)",
      caracteristiques: "• 1250x2500x1.0mm H24/H14\n• 1000x2000x2.0mm\n• 1250x2500x1.0mm H24/H14\n• 1250x2500x1.5mm\n• 1250x2500x2.0mm",
      options: "",
    },

    {
      _id: "27",
      name: "Tôle perforée Trèfle pointu brute",
      image: process.env.PUBLIC_URL + "/images/image_produits/tolerie/tole-perforee-tregle-et-rond-brute.jpg",
      category: "Tôlerie",
      reference: "PBT2",
      description: "Tôle perforée Trèfle \nSans traitement de surface \nCoefficient de vide: 43,8%\nTréfle de taille 12,21mm x 12,21mm avec espacement de 9,6mm\nIl est possible en option de couper et/ou plier la tôle sur mesure. Veuillez nous adresser une demande par mail ou par le formulaire de contact du site\nVente à la feuille\nDimension: 1,0 x 1000 x 2000mm",
      caracteristiques: "",
      options: "",
    },

    {
      _id: "28",
      name: "Tôle perforée Hexagonal brute",
      image: process.env.PUBLIC_URL + "/images/image_produits/tolerie/tole-perforee-tregle-et-rond-brute-1.jpg",
      category: "Tôlerie",
      reference: "PH",
      description: "Tôle perforée Hexagonal brute H11 T14\nSans traitement de surface \nCoefficient de vide: 61,73%\nHateur du losange: 11mm\nEspacement de 14mm\nIl est possible en option de couper et/ou plier la tôle sur mesure. Veuillez nous adresser une demande par mail ou par le formulaire de contact du site\nVente à la feuille\nDimension: 1,0 x 1000 x 2000mm ",
      caracteristiques: "",
      options: "",
    },

    // {
    //   _id: "14",
    //   name: "Tube carré",
    //   image: process.env.PUBLIC_URL + "/images/image_produits/poutrelles/tube-carre-tca2506.jpg",
    //   category: "Tubes",
    //   reference: "TCA1215",
    //   description: "",
    //   caracteristiques: "• \n• ",
    //   options: "",
    // },




  ],

  categories: [
    "Gamme de Profilé 50",
    "Gamme de Profilé 40",
    "Gamme de profilé à rupture de pont thermique 60",
    "Facades",
    "Portes accordéons",
    "Garde du corps",
    "Verrières",
    "Portails & portillons",
    "Porte dentrée",
    "Etagères",
    "Terrasses",
    "Vérandas",
    "Gamme de profilé Série froide",
    "Gamme de profilé Rupture de pont thermique",
    "Gamme de Profilé ALUK",
    "Fenêtres & porte fenêtre",
    "Façades",
    "Coulissant",
    "Fenêtres & porte fenêtre",
    "Le profil Réhau",
    "Le profil Gealan",
    "Portes Garages en aluminium",
    "Vérandas",
    "Terrasses",
    "Toitures",
    "Portails",
    "Portillons",
    "Portes dentrée",
    "Portes garages sectionnelles",
    "Découpe laser",
    "Tôlerie",
    "Pliage et cisaille",
    "Poutrelles",
    "Tubes",
    "Stores",
    "Volets roulants",
    "Rideaux métalliques",
  ],
};

export default data;
