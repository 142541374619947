import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import CataScreen from "./screens/CataScreen";
import HomeScreen from "./screens/HomeScreen";
import ProductListScreen from "./screens/ProductListScreen";
import ProductScreen from "./screens/ProductScreen";
import SearchScreen from "./screens/SearchScreen";

function App() {
  return (
    <div>
      <BrowserRouter>
        <div className="grid-container">
          <Navbar></Navbar>
          <main>
            <Route exact path="/" component={HomeScreen}></Route>
            <Route exact path="/productlist" component={ProductListScreen}></Route>
            <Route path="/product/:id" component={ProductScreen}></Route>
            <Route exact path="/:category?" component={CataScreen}></Route>
            <Route
              exact
              path="/search/name/:name?"
              component={SearchScreen}
            ></Route>
          </main>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
