import React, { useCallback, useState } from "react";
import Product from "../components/Product";
import data from "../data";

const ProductListScreen = () => {
  // const dispatch = useDispatch();
  // const productList = useSelector((state) => state.productList);
  // const { loading, error, products } = productList;
  // useEffect(() => {
  //   dispatch(listProducts({}));
  // }, [dispatch]);
  // 仅在dispatch更改时更新 优化性能


  const [state, setState] = useState({
    products: data.products,
    filters: new Set(),
  });

  const handleFilterChange = useCallback(
    (event) => {
      setState((previousState) => {
        let filters = new Set(previousState.filters); //let const
        let products = data.products;

        if (event.target.checked) {
          filters.add(event.target.value);
        } else {
          filters.delete(event.target.value);
        }

        if (filters.size) {
          products = products.filter((product) => {
            return filters.has(product.category);
          });
        }

        return {
          filters,
          products,
        };
      });
    },
    [setState]
  );

  console.log("state products => ", state.products);
  // console.log("state filters : ");
  // console.log(state.filters);
  console.log("cata => ", data.categories);

  return (
    <div>
      {/* {data.products.map((product) => (
          <Product key={product._id} product={product}></Product>
        ))} */}
      {/* if select category == 0 =>list else list with category selected */}
      {/* <ProductFilters
          catagories={data.categories}
          onFilterChange={handleFilterChange}
        /> */}

      {/* <div className="boxfilterlist"> */}

      {/* Filters */}
      {/* <div className="filterleft">
            {data.categories.map((category) => (
              <li key={category}>
                <label>
                  <input
                    onChange={handleFilterChange}
                    type="checkbox"
                    value={category}
                    className="form-check-input"
                  />
                  &nbsp;&nbsp;{category}
                </label>
              </li>
            ))}
          </div> */}
      {/* Products */}
      {/* <div className="listright"> */}

      

      <ul className="breadcrumb">
        <a href="/" className='breadcrumb-item'>Home</a>
        <a href="" className='breadcrumb-item'>Products</a>
      </ul>

      <div className="product_list">
        {state.products.map((product) => (
          <Product key={product._id} product={product}></Product>
        ))}
      </div>
    </div>
    // </div>
    // </div>
  );
};

export default ProductListScreen;
