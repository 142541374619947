import React from "react";
import { useParams } from "react-router-dom";
import Product from "../components/Product";
import data from "../data";

const SearchScreen = () => {
  const { name } = useParams();
  const listSearch = data.products.filter((x) => {
    if (name === undefined) {
      return data;
    } else {
      return x.name.toLowerCase().includes(name.toLowerCase());
    }
  });

  console.log(name);
  console.log("listSearch => ",JSON.stringify(listSearch));

  return (
    <div>
      <div className="row top">
        {listSearch.length === 0 ? (
          <div className="text-no-result">
            <h3>Utilisez moins de mots-clés ou essayez ceux-ci à la place</h3>
            <a>Aucun résultat pour {name}&nbsp;&nbsp;&nbsp;</a>
            <a href="/productlist">retournez la liste de produits</a>
          </div>
        ) : (
          <div>
            <div className="row left">{listSearch.length} Results</div>
            <div className="row center">
              {listSearch.map((x) => (
                <Product key={x._id} product={x}></Product>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchScreen;
