import React from 'react';
import data from '../data'
import { useLocation } from 'react-router-dom';
import Product from '../components/Product';

const CataScreen = () => {

    const location = useLocation();
    const category = new URLSearchParams(location.search).get('category');
    const list_products = data.products.filter(product => product.category === category);

    console.log("list_products ====> ", JSON.stringify(list_products.map((e) => e.name)));

    return (
        <div>
            {/* <ul className="breadcrumb">
                <a href="/" className='breadcrumb-item'>Home</a>
                <a href="" className='breadcrumb-item'>{`${category}`}</a>
            </ul> */}

            <div className="cata_list">
                {list_products.map((product) => (
                    <Product key={product._id} product={product}></Product>
                ))}
            </div>
        </div>
    );
}

export default CataScreen;
